<script>
/* Imports */
import { mapActions, mapGetters } from 'vuex';

/* Components */
import VatTooltip from '../components/VatTooltip.vue';
import Titles from '../components/Titles.vue';
import ProductAvailability from '../components/ProductAvailability.vue';
import ProductFootnote from '../components/ProductFootnote.vue';
import Kicker from '../components/Kicker.vue';
import ProductInfoPrice from '../components/ProductInfoPrice.vue';
import KlarnaWrapper from '../components/KlarnaWrapper.vue';
import KlarnaWidget from '../components/KlarnaWidget.vue';
import CtasWrapper from '../components/CtasWrapper.vue';
import ColorPicker from '../components/ColorPicker.vue';
import EmsFilters from '../components/EmsFilters.vue';
import AffirmWidget from '../components/AffirmWidget.vue';
import ShippingDisclaimer from '../components/ShippingDisclaimer.vue';

export default {
  name: 'ProductInfo',
  components: {
    Titles,
    Kicker,
    ProductAvailability,
    ProductInfoPrice,
    KlarnaWrapper,
    KlarnaWidget,
    CtasWrapper,
    ProductFootnote,
    ColorPicker,
    EmsFilters,
    VatTooltip,
    AffirmWidget,
    ShippingDisclaimer,
  },
  computed: {
    ...mapGetters({
      productName: 'getProductName',
      showProductName: 'getShowProductName',
      productVariation: 'getProductVariation',
      showProductVariation: 'getShowProductVariationName',
      partNumber: 'getPartNumber',
      showPartNumber: 'getShowPartNumber',
      showKicker: 'getShowKicker',
      kickerText: 'getKickerText',
      kickerTextNoStyling: 'getKickerTextNoStyling',
      kickerIsDiscontinued: 'getKickerIsDiscontinued',
      priceData: 'getPriceData',
      formattedPrice: 'getFormattedPrice',
      showPriceData: 'getShowPriceData',
      showPriceLoader: 'getShowPriceLoader',
      displayedSalePrice: 'getDisplayedSalePrice',
      displayedSavings: 'getDisplayedSavings',
      shippingDisclaimer: 'getShippingDisclaimer',
      showShippingDisclaimer: 'getShowShippingDisclaimer',
      addToCartLoading: 'getAddToCartLoading',
      showBuyAction: 'getHasBuyAction',
      addToCartHref: 'getBuyActionHref',
      addToCartText: 'getBuyActionText',
      showDealerAction: 'getHasDealerAction',
      dealerActionHref: 'getDealerActionHref',
      dealerActionText: 'getDealerActionText',
      expressWarning: 'getExpressWarning',
      showExpressWarning: 'getShowExpressWarning',
      productHeaderDetails: 'getProductHeaderDetails',
      showProductHeaderDetails: 'getShowProductHeaderDetails',
      productAvailability: 'getProductAvailability',
      showProductAvailability: 'getShowProductAvailability',
      vatToolTip: 'getVatToolTip',
      showVatToolTip: 'getShowVatToolTip',
      productLegalText: 'getProductLegalText',
      showProductLegalText: 'getShowProductLegalText',
      productMarketingSummary: 'getProductMarketingSummary',
      showProductMarketingSummary: 'getShowProductMarketingSummary',
      showAddToCartBtn: 'getShowAddToCartBtn',
      widgetMetadata: 'getWidgetMetadata',
      locale: 'getLocale',
      affirmWidgetSrc: 'getAffirmWidgetSrc',
      affirmPublicApiKey: 'getAffirmPublicApiKey',
      showAffirmWidget: 'getShowAffirmWidget',
      showKlarnaWidget: 'getShowKlarnaWidget',
    }),
    currencyCode() {
      return window?.utag_data?.currency_code;
    },
  },
  watch: {
    partNumber() {
      this.callInterstitialAccessories();
    },
  },
  mounted() {
    this.callInterstitialAccessories();
  },
  methods: {
    ...mapActions([
      'setAddToCartLoadingState',
      'callInterstitialAccessories',
      'setIsAffirmScriptLoaded',
    ]),
  },
};
</script>

<template>
  <div
    id="js__product__info"
    class="app__product__info"
  >
    <Titles
      :title="productName"
      :show-product-name="showProductName"
      :subtitle="productVariation"
      :show-subtitle="showProductVariation"
      :part-number="partNumber"
      :show-part-number="showPartNumber"
    />
    <Kicker
      id="js__product__info__kicker"
      :show-kicker="showKicker"
      :kicker-text="kickerText"
      :kicker-text-no-styling="kickerTextNoStyling"
      :kicker-is-discontinued="kickerIsDiscontinued"
    />
    <ProductAvailability
      v-if="showProductAvailability"
      :content="productAvailability"
    />
    <ProductInfoPrice
      :formatted-price="formattedPrice"
      :show-price-loader="showPriceLoader"
      :show-price-data="showPriceData"
      :displayed-sale-price="displayedSalePrice"
      :displayed-savings="displayedSavings"
    />
    <KlarnaWrapper
      v-if="showKlarnaWidget"
      id="js__product__info__klarna"
    >
      <KlarnaWidget :widget-metadata="widgetMetadata" />
    </KlarnaWrapper>
    <AffirmWidget
      v-if="showAffirmWidget"
      id="js__product__info__affirm"
      :price-data="priceData"
      :locale="locale"
      :affirm-widget-src="affirmWidgetSrc"
      :affirm-public-api-key="affirmPublicApiKey"
      :show-add-to-cart-btn="showAddToCartBtn"
      :set-is-affirm-script-loaded="setIsAffirmScriptLoaded"
    />
    <VatTooltip
      v-if="showVatToolTip"
      :content="vatToolTip"
    />
    <EmsFilters
      :currency-code="currencyCode"
    />
    <ColorPicker
      :currency-code="currencyCode"
    />
    <ShippingDisclaimer
      v-if="showShippingDisclaimer"
      :shipping-disclaimer-text="shippingDisclaimer"
    />
    <CtasWrapper
      :show-add-to-cart-btn="showAddToCartBtn"
      :add-to-cart-text="addToCartText"
      :add-to-cart-href="addToCartHref"
      :add-to-cart-loading="addToCartLoading"
      :set-add-to-cart-loading="setAddToCartLoadingState"
      :show-dealer-btn="showDealerAction"
      :dealer-text="dealerActionText"
      :dealer-href="dealerActionHref"
      :express-warning="expressWarning"
      :show-express-warning="showExpressWarning"
      :currency-code="currencyCode"
    />
    <ProductFootnote
      :product-header-details="productHeaderDetails"
      :show-product-header-details="showProductHeaderDetails"
      :product-legal-text="productLegalText"
      :show-product-legal-text="showProductLegalText"
      :product-marketing-summary="productMarketingSummary"
      :show-product-marketing-summary="showProductMarketingSummary"
      :part-number="partNumber"
    />
  </div>
</template>

<style lang="scss">
.app__product {

  &__info {
    @extend %product-section;
    flex-basis: auto;
    display: block;
    max-width: 100%;
    text-align: left;
    margin-bottom: 1rem;

    @include productBreakpoint('desktop-s') {
      flex-basis: auto;
      max-width: 50vw;
      padding-right: 7vw;
    }

    @include productBreakpoint('desktop-l') {
      padding-right: 10vw;
    }
  }
}
</style>
