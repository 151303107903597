/**
 * @constant
 * @type {object}
 * @property {number} min - The minimum price in cents.
 * @property {number} max - The maximum price in cents.
 *
 * This configuration object defines the allowable price range for Affirm payment,
 * specifying the minimum and maximum limits in cents.
 *
 * 50$ as the minimum price and 30000$ as the maximum price.
 */
export const AFFIRM_PRICE_RANGE = {
  min: 5000,
  max: 3000000,
};

export default AFFIRM_PRICE_RANGE;
