/* Imports */
import { toCents } from '../helpers/affirm/main.js';
import { mapGetters } from '../helpers/mainHelpers.js';

export const useHandleAffirmUpdate = () => {
  /* Store - Getters */
  const {
    getShowAffirmWidget: showAffirmWidget,
  } = mapGetters();

  /**
   * Converts the selected product's price to cents and updates the Affirm widget with the new price,
   * if Affirm is enabled and the widget is visible. The update is applied by setting the 'data-amount'
   * attribute of the Affirm element.
   * @param {object} selectedProduct                         - The selected product object.
   * @param {object} selectedProduct.price                   - The price details of the product.
   * @param {object} [selectedProduct.price.salePrice]       - The sale price object, if available.
   * @param {number} [selectedProduct.price.salePrice.price] - The sale price amount.
   * @param {object} [selectedProduct.price.price]           - The list price object, if available.
   * @param {number} [selectedProduct.price.price.price]     - The list price amount.
   * @returns {Promise<void>}                                - A promise indicating the completion of the update process.
   */
  const handleAffirmUpdate = async (selectedProduct) => {
    const priceInCents = toCents(selectedProduct?.price?.salePrice?.price || selectedProduct?.price?.price?.price);

    // eslint-disable-next-line no-undef
    const shouldUpdateAffirmPromos = showAffirmWidget && typeof affirm !== 'undefined' && affirm?.ui?.refresh;
    if (shouldUpdateAffirmPromos) {
      document.querySelector('.affirm-as-low-as')?.setAttribute('data-amount', priceInCents);
    }
  };

  return { handleAffirmUpdate };
};
