<script>
/* Imports */
import { mapGetters } from 'vuex';

export default {
  name: 'EmailSubscribe',
  props: {
    productHeaderDetails: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headerDetailsHtml: '',
      productSignupId: 'productSignup',
    };
  },
  computed: {
    ...mapGetters({
      partNumber: 'getPartNumber',
      locale: 'getLocale',
    }),
  },
  watch: {
    partNumber() {
      this.setHeaderDetails();
    },
  },
  mounted() {
    this.setHeaderDetails();
  },
  methods: {
    setHeaderDetails() {
      // set up product email script
      this.filterAndMountEmailScript();
      this.setHeaderDetailsHtml();
    },
    setHeaderDetailsHtml() {
      // create temp dom element to manipulate html
      const container = document.createElement('div');
      container.innerHTML = this.productHeaderDetails.replace(/<\\\/script/g, '</script');
      const productSignupEl = container.querySelector(`#${this.productSignupId}`);

      // remove current product signup script because we'll be re-adding it later so it fires
      // If we don't re-add it, it won't run on each product switch
      if (productSignupEl) productSignupEl.parentNode.removeChild(productSignupEl);

      // set the new header details html with script removed
      this.headerDetailsHtml = container.innerHTML;
    },
    filterAndMountEmailScript() {
      const {
        scriptHolder, newsletterSource, newsletterCategory, newsletterSubcategory,
      } = this.$refs;
      scriptHolder.innerHTML = '';

      // Use regex to grab the value
      // eslint-disable-next-line prefer-regex-literals
      const script = new RegExp('src="(.*?)"').exec(this.productHeaderDetails);
      if (!script) return;

      const scriptSrc = script[1];
      const scriptEl = document.createElement('script');
      scriptEl.setAttribute('src', scriptSrc);
      scriptEl.setAttribute('id', this.productSignupId);

      // Update form fields from the query params
      try {
        const url = new URL(scriptSrc);

        newsletterSource.value = url.searchParams.get('source');
        newsletterCategory.value = url.searchParams.get('category');
        newsletterSubcategory.value = url.searchParams.get('subcategory');

        // re-add product signup script
        scriptHolder.appendChild(scriptEl);

        scriptEl.addEventListener('load', () => {
          if (!window.loadEmailSignup) return;
          window.loadEmailSignup({
            locale: this.locale,
            consentTypeId: url.searchParams.get('consentTypeId'),
          });
        });
      } catch {
        // left empty on purpose
      }
    },
  },
};
</script>

<template>
  <div
    id="js__product-header-details"
    class="app__product-header-details"
  >
    <div id="newsletterSignUp">
      <input
        ref="newsletterSource"
        name="source"
        type="hidden"
        value=""
      >
      <input
        ref="newsletterCategory"
        name="category"
        type="hidden"
        value=""
      >
      <input
        ref="newsletterSubcategory"
        name="subcategory"
        type="hidden"
        value=""
      >
    </div>
    <div ref="scriptHolder" />
    <div
      v-html="headerDetailsHtml"
    />
  </div>
</template>

<style lang="scss" scoped>
.app__product-header-details {
  margin: 1em 0;
  color: $gray-medium;
  max-width: 90vw;

  h5 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: normal;
    /* stylelint-disable-next-line */
    font-family: 'Open Sans', 'HelveticaNeue', 'HelveticaNeueu', 'Arial', sans-serif;
  }

  .g__email-signup {
    padding: 1em 0;
  }

  .g__email-signup__form__input-wrapper {
    height: 40px;
    margin: 0;
  }

  .g__email-signup__form__input-wrapper__email-input {
    margin: 0;
    height: 100%;
  }

  .g__email-signup__header {
    text-align: left;
  }

  .g__email-signup__form {
    margin: 1em 0 0 0;
    text-align: left;
  }

  .g__email-signup__copy__disclaimer {
    text-align: left;
  }
}
</style>

<style lang="scss">
.app__product-header-details {

  a {
    text-decoration: underline;
  }
}
</style>
