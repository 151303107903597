<script setup>
/* Helpers */
import { mapGetters } from '../helpers/mainHelpers.js';

/* Components */
import BackToTopIcon from './icons/BackToTopIcon.vue';

/* Store - Getters */
const {
  getTranslations: translations,
} = mapGetters();

/* Handle Back To Top */
const handleOnBackToTop = () => {
  const navElement = document.getElementsByClassName('app__tabs__content__wrapper')[0];
  if (!navElement) return;
  navElement.scrollIntoView({ behavior: 'smooth' });
};
</script>

<template>
  <button
    class="app__back-to-top-button"
    tabindex="0"
    :aria-label="translations?.ITFE_PRODUCT_PAGES_BACK_TO_TOP"
    @click="handleOnBackToTop"
    @keydown.enter="handleOnBackToTop"
  >
    <BackToTopIcon
      :translations="translations"
      width="38"
      height="38"
    />
  </button>
</template>

<style lang="scss" scoped>
.app__back-to-top-button {
  animation: fadein 0.5s;
  position: fixed;
  bottom: 15em;
  right: 3em;
  cursor: pointer;
  z-index: 999;
  background: transparent;
  border: none;

  &:hover {
    filter: brightness(85%);
  }

  @include breakpoint(sm) {
    right: 2em;
  }
}
</style>
