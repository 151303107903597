export default {
  state: {
    contentfulEnvSettings: {},
  },
  getters: {
    getContentfulEnvSettings: (state) => state.contentfulEnvSettings,
  },
  mutations: {
    setContentfulEnvSettings: (state, payload) => {
      state.contentfulEnvSettings = payload;
    },
  },
  actions: {
    fetchContentfulEnvSettings({ commit }) {
      commit('setContentfulEnvSettings', window?.envSettings || {});
    },
    setContentfulEnvSettings({ commit }, payload) {
      commit('setContentfulEnvSettings', payload);
    },
  },
};
