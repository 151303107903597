/**
 * @param {string} url      - The URL to parse.
 * @param {string} buyBase  - The base URL for the buy site.
 * @param {string} wwwBase  - The base URL for the www site.
 * @returns {string}        - The parsed URL.
 * @example
 * getWwwUrl('https://buy.garmin.com/en-US/US/p/1234', 'https://buy.garmin.com', 'https://www.garmin.com') => 'https://www.garmin.com/en-US/US/p/1234'
 */

export const getWwwUrl = (url, buyBase, wwwBase) => {
  if (!buyBase || !wwwBase) return url;
  const newUrl = (url || '').replace(buyBase, wwwBase);
  return newUrl.replace(/(\/[a-z][a-z]-[A-Z][A-Z]\/)(.*\/)(p\/)/, '$1$3');
};
