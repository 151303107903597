<script>
/* Imports */
import { defineAsyncComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import VueCookies from 'vue-cookies';
import { PcRenderPromo } from '@garmin/components-product';

/* Analytics */
import {
  sanitizeAnalyticsString,
  sendAnalyticsEvent,
  sendAnalyticsPageView,
  getCategory3Data,
  getSelectedEmsOption,
  getSelectedEmsOptionIndex,
} from '../helpers/ga/analytics.js';

/* Helpers */
import {
  breakpoints,
  convertImageSource,
  suppressRelatedVideos,
} from '../helpers/mainHelpers.js';

/* Components */
import CloudinaryProductGallery from '../containers/CloudinaryProductGallery.vue';
import ProductInfo from '../containers/ProductInfo.vue';
import Breadcrumbs from '../components/Breadcrumbs.vue';

/* Composables */
import { useGenericAnalyticsDataHelper } from '../composables/useAnalyticsData.js';

/* Components - Async -> Lazy Load */
const Subnav = defineAsyncComponent({
  loader: () => import('../components/Subnav.vue'),
});
const Interstitial = defineAsyncComponent({
  loader: () => import('../containers/Interstitial.vue'),
});

export default {
  name: 'App',
  components: {
    ProductInfo,
    CloudinaryProductGallery,
    Breadcrumbs,
    Subnav,
    PcRenderPromo,
    Interstitial,
  },
  setup() {
    /* Initialize Composables */
    const { genericAnalyticsData } = useGenericAnalyticsDataHelper();
    return { genericAnalyticsData };
  },
  data() {
    return {
      initialLoad: true,
      initialHash: '',
      defaultActiveTab: '',
      showDesktopBanner: true,
    };
  },
  computed: {
    ...mapGetters([
      'getActiveProductTabs',
      'getSupplimentaryDisclaimers',
      'getPartNumber',
      'getProductActiveTabId',
      'getDefaultProductTab',
      'getIsActiveProductTab',
      'getLocale',
      'getProductId',
      'getProductVariation',
      'getBreadCrumbs',
      'getDefaultSku',
      'getPromoBanner',
      'getOverviewContainsDisclaimers',
      'getInterstitialShouldDisplay',
      'getInterstitialIsVisible',
      'getInterstitialProductPvName',
      'getInterstitialProductImage',
      'getInterstitialProductAltText',
      'getPriceData',
      'getSeoAttributes',
      'getMarketCategoryGlobal',
      'getProductCategoryGlobal',
      'getCountryCode',
      'getOptionsList',
      'getOptionListByType',
      'getGlobalProductName',
      'getActiveCompatibleDevices',
      'getProductUrl',
      'getCustomerGroups',
      'getHashIsValidProductTab',
      'getProductTabsAreSet',
      'getProductName',
      'getCloudinaryAssets',
      'getCloudinaryCloudName',
      'getCloudinarySecureDistribution',
    ]),
    subNavLabels() {
      return this.getActiveProductTabs.map((product) => product.title);
    },
    subNavContent() {
      // If there are disclaimers present in the overview bootstrap response, we append a disclaimer block in the existing wrapper
      const appendDisclaimers = this.getOverviewContainsDisclaimers && this.getSupplimentaryDisclaimers.length;

      return this.getActiveProductTabs.map((product) => {
        let tabContent = product.content;
        if (product.id === 'overview' && appendDisclaimers) {
          tabContent = this.appendDisclaimerToOverviewTab(tabContent);
        }
        // Suppress unwanted related videos of all embedded Youtube videos
        const modifiedTabContent = suppressRelatedVideos(tabContent);

        return modifiedTabContent;
      });
    },
    subNavIds() {
      return this.getActiveProductTabs.map((product) => product.id);
    },
    showMobileBanner() {
      return !this.showDesktopBanner && this.getPromoBanner;
    },
    showInterstitial() {
      return this.getInterstitialShouldDisplay && this.getInterstitialIsVisible;
    },
    // (price with discount)
    productPrice() {
      return this.getPriceData?.salePrice?.price ?? this.getPriceData?.listPrice?.price ?? '';
    },
    discount() {
      return this.getPriceData?.savings?.price ?? '';
    },
    currencyCode() {
      return window?.utag_data?.currency_code || '';
    },
    breadCrumbAnalyticsData() {
      return {
        productName: sanitizeAnalyticsString(this.getSeoAttributes?.productDisplayName),
        productPrice: this.productPrice,
        discount: this.discount,
        currency: this.currencyCode,
      };
    },
    productPageClass() {
      return window?.envSettings?.ITFE_PRODUCT_PAGE_ENABLE_STYLING_ADJUSTMENTS ? 'apply-style-updates' : null;
    },
  },
  watch: {
    async getPartNumber() {
      if (this.initialLoad) {
        this.initialLoad = false;
        this.initialUpdate();
      } else {
        await this.updateProductRoute();
        // Send analytics event when the product data is updated
        this.viewItemAnalyticsEvent();
      }
    },
  },
  mounted() {
    this.setHistoryListener();

    window.addEventListener('utag-ready', () => {
      this.viewItemAnalyticsEvent();
    });
    window.sendAnalyticsEvent = sendAnalyticsEvent;
  },
  beforeMount() {
    this.setBootstrap();
  },
  created() {
    if (this.$route.query?.pc) {
      VueCookies.set('GarminBuyPC', this.$route.query.pc, '1d', '/', '.garmin.com', true, 'None');
    }

    if (this.$route.query?.ticket) {
      const query = { ...this.$route.query };
      delete query.ticket;
      this.$router.replace({ query });
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions([
      'setBootstrap',
      'updateProductData',
      'setProductActiveTabId',
    ]),
    appendDisclaimerToOverviewTab(overviewContent) {
      /*
      Appends the supplimentary disclaimers in the existing disclaimer container in the Overview tab
      */
      const containsDisclaimerBlock = overviewContent?.includes('<pc-disclaimer-block>');
      if (containsDisclaimerBlock) {
        // if the disclaimers are wrapped using PcDisclaimerBlock component
        const EODisclaimersIndex = overviewContent?.lastIndexOf('</pc-disclaimer-block>');
        const beforeEODContent = `${overviewContent.slice(0, EODisclaimersIndex)}</pc-disclaimer-block>`;
        const afterEODContent = overviewContent.slice(EODisclaimersIndex);
        return `${beforeEODContent}${this.markupDisclaimerBlock()}${afterEODContent}`;
      }
      // otherwise, the supplimentary disclaimers are appended at the end as items
      return `${overviewContent}${this.markupDisclaimerItems()}`;
    },
    markupDisclaimerBlock() {
      /*
      Builds and returns the HTML template of disclaimer block, which encapsulates all supplimentary disclaimers
      */
      let htmlContent = '<pc-disclaimer-block>';
      this.getSupplimentaryDisclaimers.forEach((disclaimer) => {
        htmlContent += convertImageSource(disclaimer)
          ? `<pc-disclaimer-icon
              image="${convertImageSource(disclaimer)}"
            />`
          : `<pc-disclaimer-item
              content="${disclaimer}"
            />\n`;
      });
      htmlContent += '</pc-disclaimer-block>\n';
      return htmlContent;
    },
    markupDisclaimerItems() {
      /*
      Builds and returns the HTML template of disclaimer items
      This is typically used in the old overview layout, where some disclaimers aren't wrapped in a PcDisclaimerBlock
      */
      let htmlContent = '';
      this.getSupplimentaryDisclaimers.forEach((disclaimer) => {
        htmlContent += convertImageSource(disclaimer)
          ? `<pc-disclaimer-icon
              image="${convertImageSource(disclaimer)}"
            />`
          : `<pc-disclaimer-item
              class='disclaimer-block'
              content="${disclaimer}"
            />\n`;
      });
      return htmlContent;
    },
    initialUpdate() {
      const { params } = this.$router.currentRoute.value;
      const newParams = {
        locale: this.getLocale,
        productId: this.getProductId,
        partNumber: this.getPartNumber,
      };

      if (
        (
          params.productId === newParams.productId
          && params.locale === newParams.locale
          && params.partNumber === newParams.partNumber
        )
        || (
          params.productId === newParams.productId
          && params.locale === newParams.locale
          && params.partNumber === undefined
        )
        || (
          params.productId === undefined
          && params.locale === newParams.locale
          && params.partNumber === newParams.partNumber
        )
      ) {
        return;
      }
      if (params.partNumber === undefined) {
        newParams.partNumber = undefined;
        this.$router.push({
          name: 'ShortPath',
          params: newParams,
          hash: this.$router.currentRoute.value?.hash,
        });
      } else if (params.productId === undefined) {
        newParams.productId = undefined;
        this.$router.push({
          name: 'FullPath',
          params: newParams,
          hash: this.$router.currentRoute.value?.hash,
        });
      } else {
        this.$router.push({
          name: 'FullPath',
          params: newParams,
          hash: this.$router.currentRoute.value?.hash,
        });
      }
    },
    async updateProductRoute() {
      const { params } = this.$router.currentRoute.value;
      const newParams = {
        locale: this.getLocale,
        productId: this.getProductId,
        partNumber: this.getPartNumber,
      };

      if (
        params.productId === newParams.productId
        && params.locale === newParams.locale
        && params.partNumber === newParams.partNumber
      ) {
        return;
      }
      await this.$router.push({
        name: 'FullPath',
        params: newParams,
        hash: this.$router.currentRoute.value?.hash,
        query: {},
      });
    },
    setHistoryListener() {
      window.addEventListener('popstate', () => {
        this.updateHistoryProductStates();
      });
    },
    updateHistoryProductStates() {
      const prevHash = this.getProductActiveTabId;
      const prevPartNumber = this.getPartNumber;
      const { hash, params } = this.$route;
      const { partNumber } = params;
      const newHash = hash.replace('#', '');

      const hashChanged = prevHash !== newHash;
      const productChanged = partNumber && partNumber !== prevPartNumber;
      const tabIsActiveTab = this.getIsActiveProductTab(newHash);
      const tabId = tabIsActiveTab ? newHash : this.getDefaultProductTab?.id;
      const setHash = tabIsActiveTab;

      if (hashChanged) this.setProductActiveTabId({ tabId, setHash });

      if (productChanged) this.updateProductData(partNumber);
    },
    handleResize() {
      this.showDesktopBanner = (window.innerWidth > breakpoints['desktop-s']);
    },
    getAnalyticsProductName() {
      return sanitizeAnalyticsString(this.getGlobalProductName) || 'NA';
    },
    getCategory3Value() {
      const productName = this.getAnalyticsProductName();
      return getCategory3Data(productName);
    },
    getAnalyticsProductPrice() {
      if (this.getPriceData?.salePrice?.price) {
        return [this.getPriceData.salePrice.price.toString()];
      }

      if (this.getPriceData?.listPrice?.price) {
        return [this.getPriceData.listPrice.price.toString()];
      }

      // If no sale price and no list price, return ['NA']
      return ['NA'];
    },
    viewItemAnalyticsEvent() {
      const commonAnalyticsData = {
        ...this.genericAnalyticsData(
          undefined,
          undefined,
        ),
        // Send the following fields as 'NA' for now since they are not available in the current context
        creative_name: 'NA',
        creative_slot: 'NA',
        location_id: 'NA',
        promotion_name: 'NA',
        promotion_id: 'NA',
        bundle_id: 'NA',
        is_bundle: 'NA',
        sale_type: 'NA',
        index: getSelectedEmsOptionIndex(this.getOptionListByType('image')),
        product_brand: window?.utag_data?.product_brand || ['NA'],
        product_category: window?.utag_data?.product_category || ['NA'],
        product_category2: window?.utag_data?.product_category2 || ['NA'],
        product_category3: this.getCategory3Value(),
        product_category4: getSelectedEmsOption(this.getOptionsList, 0),
        product_category5: getSelectedEmsOption(this.getOptionsList, 1),
        product_id: [this.getProductId || 'NA'],
        product_name: [this.getAnalyticsProductName()],
        product_original_price: this.getPriceData?.listPrice?.price ? [this.getPriceData?.listPrice?.price.toString()] : 'NA',
        product_current_price: this.getAnalyticsProductPrice(),
        item_family: this.getCategory3Value()?.[0] || 'NA',
        is_sale: this.getPriceData?.salePrice?.price ? 'yes' : 'no',
        price_shown: window.GarminAppBootstrap.skus[window.GarminAppBootstrap.sku].showPrice ? 'yes' : 'no',
        product_sku: this.getPartNumber ? [this.getPartNumber] : ['NA'],
        product_url: window?.location?.href ? [window.location.href.split('?')[0]] : ['NA'],
        product_variant: this.getProductVariation ? [sanitizeAnalyticsString(this.getProductVariation)] : ['NA'],
      };

      const pageViewAnalyticsData = {
        ...commonAnalyticsData,
        ...{ tealium_event: 'page_view' },
      };

      const viewItemAnalyticsData = {
        ...commonAnalyticsData,
        ...{ tealium_event: 'view_item' },
      };

      sendAnalyticsPageView(pageViewAnalyticsData);
      sendAnalyticsEvent(viewItemAnalyticsData);
    },
  },
};
</script>

<template>
  <div
    id="app-root"
  >
    <g-global-styles />
    <Interstitial
      v-if="getInterstitialIsVisible"
      :pv-name="getInterstitialProductPvName"
      :image-src="getInterstitialProductImage"
      :alt-text="getInterstitialProductAltText"
    />
    <div v-show="!showInterstitial">
      <main
        :class="productPageClass"
      >
        <Breadcrumbs
          :crumbs="getBreadCrumbs"
          :analytics-data="breadCrumbAnalyticsData"
        />
        <section class="app__product">
          <CloudinaryProductGallery
            :show-desktop-banner="showDesktopBanner"
            :currency-code="currencyCode"
            :product-id="getProductId"
            :part-number="getPartNumber"
            :product-name="getProductName"
            :price-data="getPriceData"
            :market-category-global="getMarketCategoryGlobal"
            :product-category-global="getProductCategoryGlobal"
            :product-variation="getProductVariation"
            :seo-attributes="getSeoAttributes"
            :promo-banner="getPromoBanner"
            :interstitial-is-visible="getInterstitialIsVisible"
            :cloudinary-assets="getCloudinaryAssets"
            :cloud-name="getCloudinaryCloudName"
            :secure-distribution="getCloudinarySecureDistribution"
          />
          <ProductInfo />
        </section>
        <section
          v-if="showMobileBanner"
          class="app__banner__mobile"
        >
          <PcRenderPromo :content="getPromoBanner" />
        </section>
      </main>
      <aside>
        <Subnav
          :tab-labels="subNavLabels"
          :tab-content="subNavContent"
          :tab-ids="subNavIds"
          :product-variation="getProductVariation"
          :part-number="getPartNumber"
          :product-active-tab-id="getProductActiveTabId"
          :hash-is-valid-product-tab="getHashIsValidProductTab"
          :product-tabs-are-set="getProductTabsAreSet"
          :price-data="getPriceData"
          :product-id="getProductId"
          :seo-attributes="getSeoAttributes"
          :active-compatible-devices="getActiveCompatibleDevices"
          :product-url="getProductUrl"
          :customer-groups="getCustomerGroups"
          :currency-code="currencyCode"
        />
      </aside>
    </div>
  </div>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* stylelint-disable-next-line */
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    /* stylelint-disable-next-line */
    color: #2c3e50;

    &.router-link-exact-active {
      /* stylelint-disable-next-line */
      color: #42b983;
    }
  }
}

.app__product {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  @include productBreakpoint(desktop-s) {
    margin: 1em 0;
    flex-flow: row nowrap;
  }
}

.app__banner {

  &__mobile {
    justify-content: center;
    display: flex;
    margin-bottom: 2em;
  }
}
</style>
