import * as Sentry from '@sentry/vue';
import pkg from '../../package.json';

export const sentry = (app) => {
  // Sentry - Only run in production
  if (window?.location?.host === 'www.garmin.com') {
    Sentry.init({
      app,
      dsn: 'https://803612d6307a45cfbb97a1a67f208e41@o430870.ingest.sentry.io/4504720224878592',
      release: `${pkg.name}@${pkg.version}`,
      environment: 'production',
      logErrors: true,
      allowUrls: [
        // Allow errors from our application code
        /\/p\/assets\//i,
        // Allow errors from support chat widget
        /static\.garmincdn\.com/i,
        // Allow errors from klarna widget
        /[a-zA-Z-]+\.klarnaservices\.com/i,
        // Allow errors from cloudinary
        /product-gallery\.cloudinary\.com/i,
      ],
      /**
       *
       * @param {object} event     - Sentry processed event object provide to beforeSend callback
       *                           - More details can be found here: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/filtering/#using-platformidentifier-namebefore-send-
       * @returns {(object|null)} - Returns null if event is intended to be filtered out from logging to Sentry, otherwise it returns the event object
       */
      beforeSend(event) {
        const exceptionDetails = event?.exception?.values[0];
        const exceptionValue = exceptionDetails?.value;
        const exceptionStackTrace = exceptionDetails?.stacktrace;

        // Exceptions without a value or a stack trace are non-actionable
        if (!exceptionValue || !exceptionStackTrace) {
          return null;
        }

        // Otherwise log error to Sentry
        return event;
      },
    });
  }
};

export default sentry;
