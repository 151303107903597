export const kickersStore = {
  state: {
    kickerData: {},
  },
  getters: {
    getKickerData: (state) => state.kickerData,
    getKicker: (state, getters) => (getters.getProductId ? state.kickerData[getters.getProductId] : null),
    getShowKicker: (state, getters) => Boolean(getters.getKicker),
    getKickerContent: (state, getters) => getters.getKicker?.kicker || '',
    getKickerText: (state, getters) => getters.getKicker?.text || '',
    getKickerTextNoStyling: (state, getters) => getters.getKicker?.kickerTextNoStyling || '',
    getKickerGlobalName: (state, getters) => getters.getKicker?.name || '',
    getKickerIsDiscontinued: (state, getters) => getters.getKickerGlobalName?.toLowerCase()?.includes('discontinued'),
  },
  mutations: {
    /**
     * Updates the product kicker data for a specific productId.
     * It merges the new kicker data into the existing state, ensuring immutability.
     * @param {object} state                       - The current state of the Vuex store.
     * @param {object} payload                     - The data to set for the product kicker.
     * @param {string} payload.productId           - The ID of the product.
     * @param {string} payload.kicker              - The kicker content.
     * @param {string} payload.text                - The kicker text.
     * @param {string} payload.kickerTextNoStyling - The kicker content without styling.
     * @param {string} payload.name                - The global name of the kicker.
     */
    setProductKickerData(state, {
      productId,
      kicker,
      text,
      kickerTextNoStyling,
      name,
    }) {
      state.kickerData = {
        ...state.kickerData,
        [productId]: {
          kicker,
          text,
          kickerTextNoStyling,
          name,
        },
      };
    },
  },
  actions: {
    async callKickersApi({ commit, getters }) {
      const {
        getBuyGarminEndpoint,
        getLocale,
        getCountryCode,
        getProductId,
        getPriceData,
      } = getters;

      const promotionIds = getPriceData?.appliedPromotionGuids.join(',') || '';

      const url = `${getBuyGarminEndpoint}/marketing-services/products/${getProductId}/kickers?promoIds=${promotionIds}&country=${getCountryCode}&locale=${getLocale}`;

      try {
        const response = await fetch(url, {
          method: 'get',
          credentials: 'include',
        });

        // The API returns a JSON object only when a kicker is present otherwise it is blank
        const contentType = response.headers.get('content-type') || '';

        if (contentType.indexOf('application/json') === -1) {
          commit('setProductKickerData', {
            productId: getProductId,
            kicker: null,
            text: null,
            kickerTextNoStyling: null,
            name: null,
          });
          return;
        }

        const kickersData = await response.json();

        commit('setProductKickerData', {
          productId: getProductId,
          kicker: kickersData?.kicker,
          text: kickersData?.text,
          kickerTextNoStyling: kickersData?.kickerTextNoStyling,
          name: kickersData?.name,
        });
      } catch (error) {
        console.error('Error calling kickers marketing-services', error);
      }
    },
  },
};

export default kickersStore;
