/**
 * @param {string} htmlString - The HTML string to parse.
 * @returns {string}          - The parsed HTML string.
 * @example
 * parseSidebarCustomLinks('<a href="https://www.garmin.com">Garmin</a>') => [{text: 'Garmin', url: 'https://www.garmin.com'}]
 */
export const parseSidebarCustomLinks = (htmlString) => {
  if (typeof htmlString !== 'string') return htmlString;

  const html = document.createElement('div');
  html.innerHTML = htmlString;
  const sideBarLinks = [...html.getElementsByTagName('a')];

  return sideBarLinks.map((link) => ({
    text: link.textContent,
    url: link.href,
  }));
};
