/**
 * @param {string} optionsList - The full list of EMS options to filter
 * @param {number} index       - This maps to the index of the top level items in optionsList.
 *                               Used to determine which entry in the optionsList to find options to filter through
 * @returns {Array}            - The selected EMS option text. If no match is found, then this returns ['NA']
 * @example
 * getSelectedEmsOption([emsOptionsList], index) => ['41mm']
 */
export const getSelectedEmsOption = (optionsList, index) => {
  if (!Array.isArray(optionsList)) {
    console.warn('Expected optionsList to be an array');
    return ['NA'];
  }

  if (!optionsList.length) {
    console.warn('Expected optionsList to contain values');
    return ['NA'];
  }

  if (!optionsList?.[index]?.options || !Array.isArray(optionsList[index]?.options)) {
    console.warn('Expected optionsList options to be an array');
    return ['NA'];
  }

  const result = optionsList[index].options.filter((item) => item?.selected === true);

  // If after we filter down to the only the selected EMS option, there are no entries in the list,
  // return ['NA']
  if (result?.length === 0) {
    console.warn('Filtering for selected EMS option resulted in an empty array/no matches');
    return ['NA'];
  }

  // Check that 'text' property exists on the first object in the results array
  // then set that 'text' value to an array
  return result?.[0]?.text ? [result[0].text] : ['NA'];
};

export default getSelectedEmsOption;
