/**
 * Returns a string with parameters added to a parametrized URL
 * @param {string} url - URL with parameters
 * @param {object} params - Object with parameters
 * @returns {string} - URL with parameters replaced
 * @example
 * templateBuilder(https://buy.garmin.com/{{partNum}}.json, {partNum: '1234'}) => https://buy.garmin.com/1234.json
 */
export const templateBuilder = (url, params) => {
  const keys = Object.keys(params);

  // Remove all whitespaces
  let newUrl = (url || '').replace(/\s+/g, '');

  keys.forEach((key) => {
    newUrl = newUrl.replace(`{{${key}}}`, params[key]);
  });

  return newUrl;
};
