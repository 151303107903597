<script setup>
/* Imports */
import {
  onBeforeMount,
  computed,
  watch,
} from 'vue';

/* Helpers */
import {
  mapGetters,
  mapActions,
} from '../helpers/mainHelpers.js';

/* Composables */
import { useBackToTopBtn } from '../composables/useBackToTopBtn.js';
import { useGenericAnalyticsDataHelper } from '../composables/useAnalyticsData.js';

/* Components */
import VerticalNav from '../components/VerticalNav.vue';
import BackToTopBtn from '../components/BackToTopBtn.vue';

/* Analytics */
import {
  sanitizeAnalyticsString,
  sendAnalyticsEvent,
} from '../helpers/ga/analytics.js';

/* Inject composables */
const {
  backToTopBtnRef,
  showBackToTopBtn,
} = useBackToTopBtn();
const { genericAnalyticsData } = useGenericAnalyticsDataHelper();

/* Store - Getters */
const {
  getMapsTabHeadingTranslations: mapsTabTranslations,
  getSelectedRegion: selectedRegion,
  getRegionList: regionList,
  getMapCardsList: mapCards,
  getCategoryList: categoryList,
  getDefaultCategory: defaultCategory,
  getMapsTabErrorMessage: errorMessage,
  getIsError: errorState,
  getProductActiveTabId: activeTabId,
} = mapGetters();

/* Store - Actions */
const {
  loadMapsData,
  changeRegion,
  changeCategory,
} = mapActions();

/* Handle load data */
onBeforeMount(() => {
  loadMapsData();
});

/* Handle change region */
const isSelected = computed((region) => (region === selectedRegion));

/* Handle change category */
const handleChangeCategory = (payload) => changeCategory(payload);

/* Handle map card click */
const handleMapCardClick = (card, index) => {
  const analyticsData = {
    ...genericAnalyticsData(
      'Maps Tab',
      'Maps Tab',
    ),
    index: index + 1 || 'NA',
    product_id: card?.id || 'NA',
    product_brand: window?.utag_data?.product_brand[0] || 'NA',
    product_category: 'Maps',
    product_variant: sanitizeAnalyticsString(card?.heading?.content) || 'NA',
    product_name: sanitizeAnalyticsString(card?.heading?.content) || 'NA',
    product_url: card?.linkUrl || 'NA',
    tealium_event: 'select_item',
  };

  sendAnalyticsEvent(analyticsData);
};

/* Send view item list analytics event */
const sendVILMapsEvent = (cards) => {
  if (!cards) return;

  const analyticsData = {
    ...genericAnalyticsData(
      'Maps Tab',
      'Maps Tab',
    ),
    index: cards?.map((card, index) => index + 1 || 'NA'),
    product_id: cards?.map((card) => card?.id || 'NA'),
    product_brand: cards?.map(() => window?.utag_data?.product_brand[0] || 'NA'),
    product_category: cards?.map(() => 'Maps'),
    product_variant: cards?.map((card) => sanitizeAnalyticsString(card?.heading?.content) || 'NA'),
    product_name: cards?.map((card) => sanitizeAnalyticsString(card?.heading?.content) || 'NA'),
    product_url: cards?.map((card) => card?.linkUrl || 'NA'),
    tealium_event: 'view_item_list',
  };

  sendAnalyticsEvent(analyticsData);
};

/* Send analytics event when cards get updated */
watch(() => mapCards.value, (newCards) => {
  if (activeTabId.value === 'maps') {
    sendVILMapsEvent(newCards);
  }
});

/* Send analytics event when maps tab is loaded or selected */
watch(() => activeTabId.value, (activeTab) => {
  if (activeTab === 'maps') {
    sendVILMapsEvent(mapCards.value);
  }
});
</script>

<template>
  <div ref="backToTopBtnRef" />
  <div v-if="errorState">
    <div class="app__product__maps">
      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
      <g-copy
        class="app__product__maps__error"
        v-html="errorMessage"
      />
      <!-- eslint-enable vue/no-v-text-v-html-on-component -->
    </div>
  </div>
  <div v-else>
    <div class="app__product__maps">
      <div class=" app__product__maps__columns">
        <div class="app__product__maps__columns-left">
          <div class="app__product__maps__columns-left__drop-down">
            <g-drop-down
              :label="mapsTabTranslations?.ITFE_PRODUCT_PAGES_MAP_TAB_CONTINENT_LABEL"
            >
              <select
                :value="selectedRegion"
                name="countryDropDown"
                @change="(event) => changeRegion(event?.target?.value)"
              >
                <option
                  v-for="region in regionList"
                  :key="region"
                  :selected="isSelected"
                >
                  {{ region }}
                </option>
              </select>
            </g-drop-down>
          </div>
          <div class="list">
            <VerticalNav
              :elements="categoryList"
              :default-category="defaultCategory"
              @tile-clicked="(payload) => handleChangeCategory(payload)"
            />
          </div>
        </div>
        <div class="app__product__maps__columns-line" />
        <div
          class="app__product__maps__columns-right"
          data-testid="maps-tab-cards"
        >
          <g-card
            v-for="(mapCard, index) in mapCards"
            :key="mapCard?.id"
            theme="light"
            :card="mapCard"
            hover="true"
            class="app__product__maps__columns-right__card"
            @click="handleMapCardClick(mapCard, index)"
          />
        </div>
      </div>
    </div>
    <BackToTopBtn v-if="showBackToTopBtn" />
  </div>
</template>

<style scoped lang="scss">
.app__product__maps {

  &__error {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding-top: 1rem;
    overflow: hidden;

    @include breakpoint(md) {
      padding-top: 4rem;
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }

    &-left {
      padding-right: 0.3em; // Match Accessories Nav

      @include breakpoint(md) {
        flex-basis: 21.429em; // 300px -> Match Accessories Nav
      }

      &__drop-down {
        padding-bottom: 1rem;
        padding-left: 1.5rem;

        .g__dropdown {

          &__label__text {
            font-size: 1.2em;
          }

          &__wrapper {
            padding-top: 0.5em;
          }

          select {
            font-size: 1em;
            font-style: normal;
            @include font-primary-weight-regular();
            color: $color-black;
          }
        }
      }
    }

    &-right {
      flex: 1;
      justify-content: flex-start;
      display: flex;
      flex-flow: column wrap;
      padding-top: 1em;

      @include breakpoint(xs) {
        flex-direction: row;
      }

      @include breakpoint(md) {
        padding-top: 0;
      }

      &__card {
        width: auto;

        @include breakpoint(sm) {
          // stylelint-disable-next-line
          width: calc(50% - 2rem) !important;
        }

        @include breakpoint(md) {
          // stylelint-disable-next-line
          width: calc(33.33% - 2rem) !important;
        }
      }
    }

    &-line {
      border-bottom: 1px solid $color-gray;
      width: 100%;
      margin: auto;
      padding-top: 2rem;

      @include breakpoint(md) {
        width: 0;
        margin: 0;
        border-bottom: 0;
        padding-top: 0;
      }
    }
  }
}

.g__card .image img {
  // stylelint-disable-next-line
  width: 80% !important;
}

// used to set the space between the Map cards
:deep(.g__card) {
  margin: 0.7rem;
}
</style>

<style lang="scss">
// Global style override needed until we remove base lib
.app__product__maps {

  &__heading {

    a {
      text-decoration: underline;
    }
  }
}
</style>
