<script setup>
/* Imports */
import { computed } from 'vue';

/* Analytics */
import { sendAnalyticsEvent } from '../helpers/ga/analytics.js';

/* Props */
const props = defineProps({
  crumbs: {
    type: Array,
    default: () => [],
  },
  analyticsData: {
    type: Object,
    default: () => {},
  },
});

/* Handle Analytics */
// TODO: When refactoring/implementing new analytics, make sure to update the analyticsData object
// to remove the analyticsData prop and use at the component level the data which is needed for the analytics
const breadCrumbsAnalyticsEvent = (crumb) => {
  const analyticsData = {
    tealium_event: 'product_page_breadcrumb_click',
    link_type: 'text',
    product_name: window?.utag_data?.product_name || props.analyticsData.productName || '',
    event_category: 'Product Page >> Breadcrumbs',
    event_action: 'click',
    event_label: crumb?.contentfulName ?? '',
    product_sku: window?.GarminAppBootstrap?.sku,
    currency: props.analyticsData?.currency || 'NA',
  };

  sendAnalyticsEvent(analyticsData);
};

/* Computed - Breadcrumbs */
const breadcrumbs = computed(() => props.crumbs?.map(({ contentfulName, contentfulUrl }, index) => ({
  id: index,
  text: contentfulName,
  url: contentfulUrl,
  uaEvent: `Breadcrumbs, click, ${contentfulName}`,
  onClick: () => {
    breadCrumbsAnalyticsEvent({ contentfulName, contentfulUrl });
  },
  onKeyDown: () => {
    breadCrumbsAnalyticsEvent({ contentfulName, contentfulUrl });
  },
})));

const showBreadcrumbs = computed(() => props.crumbs?.every((crumb) => Boolean(crumb.contentfulUrl) && Boolean(crumb.contentfulName)));
</script>

<template>
  <g-breadcrumbs
    v-if="showBreadcrumbs"
    :breadcrumbs.prop="breadcrumbs"
    class="app__breadcrumbs"
  />
</template>

<style lang="scss">
.app__breadcrumbs {
  display: block;
  margin: 1em;
}
</style>
