/**
 * List of query params eComm team wants passed to cart
 */
const cartConversionQueryParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
];

/**
 * Returns stringified version
 * @param {object} queryParams - Query params object
 * @returns {string}           - Stringified version of cart conversion query params
 * @example
 * const stringifiedCartConversionQueryParams = convertCartConversionQueryParamsToString(queryParams);
 */
export const convertCartConversionQueryParamsToString = (queryParams) => {
  const cartQueryParams = {};
  const currentQueryParams = queryParams || {};

  cartConversionQueryParams.forEach((param) => {
    cartQueryParams[param] = currentQueryParams[param];
  });

  const queryParamsArray = [];
  Object.keys(cartQueryParams).forEach((key) => {
    if (cartQueryParams[key]) {
      queryParamsArray.push(`&${key}=${cartQueryParams[key]}`);
    }
  });

  return queryParamsArray.join('');
};
