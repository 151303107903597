<script setup>
/* Imports */
import { ref, watch } from 'vue';

/* Props */
const props = defineProps({
  elements: {
    type: Array,
    default: () => [],
  },
  defaultCategory: {
    type: String,
    default: () => '',
  },
});

/* Emits */
const emit = defineEmits(['tileClicked']);

/* State */
const state = ref({
  selectedItem: '',
});

/* Handle selected option */
const optionSelected = (element) => (
  state.value.selectedItem === element?.key
    ? {
      optionState: 'selected',
      optionTheme: 'dark',
    }
    : {
      optionState: 'unselected',
      optionTheme: 'light',
    }
);

/* React if the currently selected item is removed from the list of items to render */
watch(() => props.elements, (newOptions) => {
  if (!(newOptions.find((newOption) => newOption?.key === state.value.selectedItem))) {
    // Handle case of the default category is no longer in the list, will default to first item in the list
    state.value.selectedItem = (newOptions.find((elm) => elm?.key === props.defaultCategory)) ? props.defaultCategory : newOptions[0]?.key;
    emit('tileClicked', state.value.selectedItem);
  }
});

/* Set the default selected item */
props.elements.forEach((element) => {
  if (element.isActive) {
    state.value.selectedItem = element?.key;
  }
});

const handleClick = (key) => {
  // safely handle null/ undefined keys
  if (!key) return;
  state.value.selectedItem = key;
  emit('tileClicked', key);
};
</script>

<template>
  <div class="app__vnav">
    <div class="app__vnav__elements">
      <div
        v-for="element in props.elements"
        :key="element?.key"
        class="app__vnav__elements__element"
        :class="optionSelected(element).optionState"
      >
        <a
          class="app__vnav__elements__element__link"
          @click="handleClick(element?.key)"
        >
          <g-heading
            :content="element?.categoryLabel"
            size="5"
            :theme="optionSelected(element).optionTheme"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.app__vnav {
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  width: 100%;

  &__elements {

    &__element {
      padding: 1em 1em 1em 2em;
      transition: all 0.5s;

      &.selected {
        background-color: $color-black;
        color: $color-white;
      }

      &.unselected {
        background-color: $color-white;
        @extend %text-black;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  /* Match the Accessories Nav */
  .g__heading h5 {
    margin: 0;
    font-family: $font-roboto;
    @extend %sidebar-links;
    letter-spacing: normal;
  }
}

/* Remove anchor tag styling */
a.app__vnav__elements__element__link {
  @extend %text-decoration-none;
}
</style>
