/* Helpers */
import { request, templateBuilder } from '../helpers/mainHelpers.js';

export default {
  state: {
    showAddToCart: false,
    addToCartLoading: false,
    actions: null,
    expressWarning: '',
    hasAvailableSeats: false,
  },
  getters: {
    getShowAddToCartBtn(state) {
      return state.showAddToCart;
    },
    getAddToCartLoading(state) {
      return state.addToCartLoading;
    },
    getActions(state) {
      return state.actions;
    },
    getBuyAction(state, getters) {
      const actions = getters.getActions;
      return actions ? actions.buy : null;
    },
    getHasBuyAction(state, getters) {
      return !!getters.getBuyAction;
    },
    getBuyActionHref(state, getters) {
      const action = getters.getBuyAction;
      return action ? action.href : '';
    },
    getBuyActionText(state, getters) {
      const action = getters.getBuyAction;
      return action ? action.text : '';
    },
    getDealerAction(state, getters) {
      const action = getters.getActions;
      return action ? action.dealer : null;
    },
    getHasDealerAction(state, getters) {
      return !!getters.getDealerAction;
    },
    getDealerActionHref(state, getters) {
      const action = getters.getDealerAction;
      return action ? action.href : '';
    },
    getDealerActionText(state, getters) {
      const action = getters.getDealerAction;
      return action ? action.text : '';
    },
    getExpressWarning(state) {
      return state.expressWarning;
    },
    getShowExpressWarning(state, getters) {
      return !!getters.getExpressWarning.length;
    },
    getHasAvailableSeats(state) {
      return state.hasAvailableSeats;
    },
  },
  mutations: {
    setShowAddToCart(state, payload) {
      state.showAddToCart = payload;
    },
    setAddToCartLoading(state, payload) {
      state.addToCartLoading = payload;
    },
    setActions(state, payload) {
      state.actions = payload;
    },
    setExpressWarning(state, payload) {
      state.expressWarning = payload || '';
    },
    setHasAvailableSeats(state, payload) {
      state.hasAvailableSeats = payload;
    },
  },
  actions: {
    async setCtas({ commit, dispatch }, payload) {
      const { actions, expressWarning } = payload;

      commit('setActions', actions);
      commit('setExpressWarning', expressWarning);
      await dispatch('callAvailableSeats');
      await dispatch('checkAddToCartVisibility');
    },
    setAddToCartLoadingState({ commit }, payload) {
      commit('setAddToCartLoading', payload);
    },
    async callAvailableSeats({ commit, getters }) {
      const partNum = getters.getPartNumber;
      const checkAvailableSeats = getters.getCheckAvailableSeats;
      const urlTemplate = getters.getAvailableSeatsUrl;

      if (!urlTemplate || !checkAvailableSeats) return;

      const url = templateBuilder(urlTemplate, { partNum });

      const response = await request({
        url,
      }).catch(() => {
        throw new Error('Unable to call available seats service');
      });

      const noAvailableSeats = typeof response === 'undefined' || (response?.ErrorMessage?.length > 0) || response?.Count === 0;

      commit('setHasAvailableSeats', !noAvailableSeats);
    },
    async checkAddToCartVisibility({ commit, getters }) {
      commit('setShowAddToCart', false);

      const actions = getters.getActions;

      const sellable = getters.getSellable;
      const availableForPurchase = sellable === true;

      if (getters.getCheckAvailableSeats) {
        if (actions && actions.buy && availableForPurchase && getters.getHasAvailableSeats) {
          commit('setShowAddToCart', true);
        }
      } else if (actions && actions.buy && availableForPurchase) {
        commit('setShowAddToCart', true);
      }
    },
  },
};
