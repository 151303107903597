/**
 * Get the analytics price data for a given product.
 * @param {object} product                                    - The product object.
 * @param {object} [product.priceObj]                         - The price object of the product.
 * @param {object} [product.priceObj.salePrice]               - The sale price object of the product.
 * @param {number|string} [product.priceObj.salePrice.price]  - The sale price of the product.
 * @param {object} [product.priceObj.listPrice]               - The list price object of the product.
 * @param {number|string} [product.priceObj.listPrice.price]  - The list price of the product.
 * @param {boolean} [product.sellable]                        - Indicates if the product is sellable.
 * @param {boolean} [product.showPrice]                       - Indicates if the product price should be shown.
 * @returns {object} An object containing analytics price data, with the following properties:
 * - {number|string} price: The sale price of the product, the list price if there is no sale, or 'NA' if not available.
 * - {number|string} listPrice: The list price of the product, or 'NA' if not available.
 * - {string} availability: The availability status of the product ('In Stock' or 'Out of Stock').
 * - {string} isSale: Indicates if the product is on sale ('yes' or 'no').
 * - {string} inStock: Indicates if the product is in stock ('yes' or 'no').
 * - {string} priceShown: Indicates if the product price is shown based on `showPrice` or if `listPrice` exists ('yes' or 'no').
 */
export const getAnalyticsPriceData = (product) => ({
  price: product?.priceObj?.salePrice?.price ?? product?.priceObj?.listPrice?.price ?? 'NA',
  listPrice: product?.priceObj?.listPrice?.price ?? 'NA',
  availability: product?.sellable ? 'In Stock' : 'Out of Stock',
  isSale: product?.priceObj?.salePrice ? 'yes' : 'no',
  inStock: product?.sellable ? 'yes' : 'no',
  /* eslint-disable-next-line no-nested-ternary */
  priceShown: product?.showPrice ? 'yes' : (product?.priceObj?.listPrice?.price ? 'yes' : 'no'),
});
