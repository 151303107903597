export default {
  state: {
    translations: {},
    contentfulRegions: {},
  },
  getters: {
    getTranslations(state) {
      return state.translations;
    },
    getContentfulRegions(state) {
      return state.contentfulRegions;
    },
    getSupplimentaryDisclaimers(state) {
      const supplimentaryDisclaimers = state.contentfulRegions?.PRODUCT_PAGE_SUPPLEMENTARY_DISCLAIMERS;
      return supplimentaryDisclaimers ? Object.values(supplimentaryDisclaimers) : [];
    },
    getAddToCart(state) {
      return state.translations.addToCart;
    },
    getExpandSection(state) {
      return state.translations.expand;
    },
    getCollapseSection(state) {
      return state.translations.collapse;
    },
    getAccessoriesTabError(state) {
      return state.translations.accessoriesTabError;
    },
    getAccessoriesJsonUrl(state) {
      return state.translations.accessoriesJsonUrl;
    },
    getPriceSaveText(state) {
      return state.translations.save;
    },
    getDevicesJsonUrl(state) {
      return state.translations.devicesJsonUrl;
    },
    getProductUrl(state, getters) {
      return `${getters.getWwwGarminEndpoint}/${getters.getLocale}/p/{{productId}}`;
    },
    getCompatibleTitle(state) {
      return state.translations.compatibleTitle;
    },
    getManualsUrl(state) {
      return state.translations.manualsUrl;
    },
    getSupportCenterUrl(state) {
      return state.translations.supportCenterUrl;
    },
    getCompatibleProductsJsonUrl(state) {
      return state.translations.compatibleProductsJsonUrl;
    },
    getCrossSellsJsonUrl(state) {
      return state.translations.crossSellsJsonUrl;
    },
    getUpSellsJsonUrl(state) {
      return state.translations.upSellsJsonUrl;
    },
    getMapsJsonUrl(state, getters) {
      return getters.getTranslations.mapsJsonUrl;
    },
    getAddToCartUrl(state, getters) {
      return getters.getTranslations.addToCartUrl;
    },
    getCheckAvailableSeats(state, getters) {
      return getters.getTranslations.checkAvailableSeats;
    },
    getAvailableSeatsUrl(state, getters) {
      return getters.getTranslations.availableSeatsUrl;
    },
    getSeoUrl(state, getters) {
      return `${getters.getWwwGarminEndpoint}/${getters.getLocale}/{{ seoUrl }}`;
    },
    getViewCartUrl(state, getters) {
      return getters.getTranslations.viewCartUrl;
    },
    getVatToolTip(state, getters) {
      return getters.getTranslations.vatToolTip;
    },
    getShowVatToolTip(state, getters) {
      return !!getters.getVatToolTip;
    },
    getPartNumberTitle(state, getters) {
      return getters.getTranslations.partNumber;
    },
    getMapFormatNames(state, getters) {
      return getters.getTranslations.mapFormatTranslations;
    },
    getAddedToCart(state) {
      return state.translations.addedToCart;
    },
    getContinueShopping(state) {
      return state.translations.continueShopping;
    },
    getViewCart(state) {
      return state.translations.viewCart;
    },
    getShopForAccessories(state) {
      return state.translations.shopForAccessories;
    },
    getInterstitialApiErrorMessage(state) {
      return state.translations.interstitialApiErrorMessage;
    },
    getCustomizeButtonText(state) {
      return state.translations.ITFE_PRODUCT_PAGES_CUSTOMIZE_BUTTON;
    },
  },
  mutations: {
    setTranslations(state, payload) {
      state.translations = payload;
    },
    setContentfulRegions(state, payload) {
      state.contentfulRegions = payload;
    },
  },
  actions: {
    setContent({ commit }, payload) {
      commit('setTranslations', payload);
    },
    setContentfulRegions({ commit }, payload) {
      commit('setContentfulRegions', payload);
    },
  },
};
