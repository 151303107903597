<script setup>
/* Imports */
import {
  ref,
  computed,
  onMounted,
  watch,
} from 'vue';
import { PcRenderPromo } from '@garmin/components-product';

/* Analytics */
import {
  sendAnalyticsEvent,
  sanitizeAnalyticsString,
} from '../helpers/ga/analytics.js';

/* Props */
const props = defineProps({
  showDesktopBanner: {
    type: Boolean,
    default: false,
  },
  currencyCode: {
    type: Array,
    default: () => [],
  },
  productId: {
    type: String,
    default: '',
  },
  partNumber: {
    type: String,
    default: '',
  },
  productName: {
    type: String,
    default: '',
  },
  priceData: {
    type: Object,
    default: () => ({}),
  },
  marketCategoryGlobal: {
    type: String,
    default: '',
  },
  productCategoryGlobal: {
    type: String,
    default: '',
  },
  productVariation: {
    type: String,
    default: '',
  },
  seoAttributes: {
    type: Object,
    default: () => ({}),
  },
  promoBanner: {
    type: String,
    default: '',
  },
  interstitialIsVisible: {
    type: Boolean,
    default: false,
  },
  cloudinaryAssets: {
    type: Array,
    default: () => [],
  },
  cloudName: {
    type: String,
    default: '',
  },
  secureDistribution: {
    type: String,
    default: '',
  },
});

/* State */
const state = ref({
  cloudinaryGallery: null,
  mainImageClicked: false,
});

/* Computed */
const galleryWidgetClass = computed(() => ({
  'hide-slider': props.cloudinaryAssets?.length === 1,
}));

const shouldRenderPromoBanner = computed(() => props.showDesktopBanner && Boolean(props.promoBanner));

/* Cloudinary Gallery Widget Rendering */
const renderCloudinaryWidget = () => {
  const self = {
    productId: props.productId,
    productName: props.productName,
    productVariation: props.productVariation,
    priceData: props.priceData,
    partNumber: props.partNumber,
    currencyCode: props.currencyCode,
    seoAttributes: props.seoAttributes,
    marketCategoryGlobal: props.marketCategoryGlobal,
    productCategoryGlobal: props.productCategoryGlobal,
    secureDistribution: props.secureDistribution,
    cloudName: props.cloudName,
    cloudinaryAssets: props.cloudinaryAssets,
  };

  // eslint-disable-next-line no-undef
  state.value.cloudinaryGallery = cloudinary.galleryWidget({
    container: '#cloudinary-product-gallery',
    cloudName: self.cloudName,
    zoomProps: {
      type: 'popup',
      steps: 3,
      stepLimit: true,
      level: 1.3,
      showTip: 'never',
    },
    zoomPopupProps: {
      zIndex: 100000,
    },
    videoProps: {
      playerType: 'cloudinary',
      controls: 'all',
      autoplay: true,
      sound: true,
    },
    thumbnailProps: {
      width: 80,
      height: 80,
      spacing: 8,
      navigationColor: '#cacaca',
      navigationIconColor: '#ffffff',
      borderColor: '#cccccc',
      borderWidth: 2,
      selectedStyle: 'border',
      selectedBorderPosition: 'all',
      selectedBorderWidth: 2,
      selectedBorderColor: '#000000',
      selectedBorderOpacity: 1,
    },
    viewportBreakpoints: [{
      breakpoint: 1022,
      carouselStyle: 'thumbnails',
      carouselLocation: 'bottom',
      thumbnailProps: {
        width: 64,
        height: 64,
      },
    }],
    secure: true,
    privateCdn: !!self.secureDistribution,
    secureDistribution: self.secureDistribution,
    mediaAssets: self.cloudinaryAssets,
  });

  state.value.cloudinaryGallery.render();

  const sendCloudinaryAnalyticsEvent = (customAnalyticsData) => {
    const analyticsData = {
      tealium_event: 'product_carousel_click',
      link_type: 'image',
      product_id: self.productId,
      product_name: sanitizeAnalyticsString(self.productName),
      product_variant: sanitizeAnalyticsString(self.productVariation),
      event_category: `Product Page >> ${sanitizeAnalyticsString(self.productName)}`,
      event_action: 'click',
      product_price: self.priceData?.salePrice?.price ?? self.priceData?.listPrice?.price ?? '',
      product_sku: self.partNumber,
      discount: self.priceData?.savings?.price ?? '',
      currency: self.currencyCode || 'NA',
      promotion: 'no',
      product_category: self.marketCategoryGlobal,
    };

    const combinedAnalyticsData = { ...analyticsData, ...customAnalyticsData };

    sendAnalyticsEvent(combinedAnalyticsData);
  };

  // Event bindings
  state.value.cloudinaryGallery.on('thumbclick', () => {
    sendCloudinaryAnalyticsEvent({
      event_label: 'image gallery thumbnail',
      event_value: '',
    });
  });

  state.value.cloudinaryGallery.on('thumbnext', () => {
    sendCloudinaryAnalyticsEvent({
      event_label: 'image gallery thumbnail arrow',
      event_value: 'right',
    });
  });

  state.value.cloudinaryGallery.on('thumbprev', () => {
    sendCloudinaryAnalyticsEvent({
      event_label: 'image gallery thumbnail arrow',
      event_value: 'left',
    });
  });

  state.value.cloudinaryGallery.on('viewernext', () => {
    sendCloudinaryAnalyticsEvent({
      event_label: 'image gallery main image arrow',
      event_value: 'right',
    });
  });

  state.value.cloudinaryGallery.on('viewerprev', () => {
    sendCloudinaryAnalyticsEvent({
      event_label: 'image gallery main image arrow',
      event_value: 'left',
    });
  });

  state.value.cloudinaryGallery.on('zoomin', () => {
    if (!state.value.mainImageClicked) {
      sendCloudinaryAnalyticsEvent({
        event_label: 'image gallery',
        event_value: 'click',
      });
      state.value.mainImageClicked = true;
    } else {
      sendCloudinaryAnalyticsEvent({
        event_label: 'image gallery',
        event_value: 'zoom in',
      });
    }
  });

  state.value.cloudinaryGallery.on('zoomout', () => {
    sendCloudinaryAnalyticsEvent({
      event_label: 'image gallery',
      event_value: 'zoom out',
    });
  });
};

const updateCloudinaryAssets = () => {
  if (!state.value.cloudinaryGallery) {
    renderCloudinaryWidget();
    return;
  }

  state.value.cloudinaryGallery.update({
    mediaAssets: props.cloudinaryAssets,
  });
};

/* Watchers */
watch(() => props.partNumber, updateCloudinaryAssets);
watch(() => props.interstitialIsVisible, (newValue) => {
  if (newValue) {
    state.value.cloudinaryGallery?.destroy();
  } else {
    updateCloudinaryAssets();
  }
});

/* Lifecycle hooks */
onMounted(() => {
  renderCloudinaryWidget();
});
</script>

<template>
  <div class="app__product__images">
    <div
      id="cloudinary-product-gallery"
      :class="galleryWidgetClass"
    />
    <div
      v-if="shouldRenderPromoBanner"
      class="app__render-promo-container"
    >
      <PcRenderPromo
        class="app__render-promo-container__banner"
        :content="props.promoBanner"
        data-test="promo-banner"
      />
    </div>
  </div>
</template>

<style lang="scss">
/* stylelint-disable @stylistic/max-line-length, selector-class-pattern */
$cloudinary-product-gallery-l: 600px;
$cloudinary-product-gallery-m: 560px;
$cloudinary-product-gallery-s: 450px;

$cloudinary-product-gallery-thumb-size-l: 84px;
$cloudinary-product-gallery-thumb-b-margin: 8px;
$cloudinary-product-gallery-thumb-show-count: 5;
$cloudinary-product-gallery-thumbs-con-height: $cloudinary-product-gallery-thumb-show-count * ($cloudinary-product-gallery-thumb-size-l + $cloudinary-product-gallery-thumb-b-margin);

.app__product {

  &__images {
    @extend %product-section;
    position: relative;
    text-align: right;
    flex-basis: 100%;
    margin-left: 0;

    @include productBreakpoint(desktop-s) {
      flex-basis: 50%;
      max-width: 50vw;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding-right: 1rem;
    }
    @include productBreakpoint(desktop-m) {
      padding-right: 30px;
    }
  }
}

#cloudinary-product-gallery {
  width: 100vw;
  max-width: 100vw;
  padding: 0 0.5rem 0 1rem;
  margin: 0 auto;

  @include productBreakpoint(tablet-s) {
    max-width: calc(50vw - 1rem);
  }

  @include productBreakpoint(desktop-m) {
    width: $cloudinary-product-gallery-s;
    margin: 0;
  }
  @include productBreakpoint(gallery-m) {
    width: $cloudinary-product-gallery-m;
    margin: 0;
  }
  @include productBreakpoint(gallery-l) {
    width: $cloudinary-product-gallery-l;
    margin: 0;
  }

  .assetWrapper {
    overflow: hidden;
  }

  div[data-test = 'zoom-flyout-wrap'] img {
    background: $color-white;
  }

  button {
    touch-action: manipulation;
  }

  &.hide-slider div[data-test = 'gallery-thumbnail-wrap'] {
    visibility: hidden;
    display: none;

    @include productBreakpoint(desktop-s) {
      display: block;
    }
  }

  /* Hide thumbnails on mobile */
  [data-test = 'gallery-thumbnail-wrap'] {
    display: none;
    visibility: hidden;

    @include breakpoint(sm) {
      display: block;
      visibility: visible;
    }
  }
}

/*
  These styles are needed so we make sure the Cloudinary Video
  player renders as intended and is ADA compliant
*/

.vjs-menu-item {
  color: $color-white;
}

.vjs-time-control.vjs-time-divider {
  margin-top: 8px;
}

.cld-video-player .vjs-control-bar .vjs-play-progress::before {
  /* stylelint-disable-next-line declaration-no-important */
  top: -0.75em !important;
}

.vjs-control.vjs-cloudinary-button {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

/* Spacing between product image and promo banner */
.app__render-promo-container__banner {
  margin-top: 1.5rem;

  > p {
    margin-bottom: 0;
  }
}
</style>
