<script>
export default {
  name: 'VatTooltip',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div
    id="js__product__vat-tooltip"
    class="app__product__vat-tooltip"
    data-nosnippet
  >
    <span
      v-html="content"
    />
  </div>
</template>

<style lang="scss">
.app__product__vat-tooltip {
  font-size: 0.8em;
}
</style>
