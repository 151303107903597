/* Imports */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import { sentry } from './modules/sentry.js';
import store from './store/index.js';

// Global PPGN containers registration
import registerPPGNContainers from './modules/registerPPGNContainers.js';
// Global Components registration
// eslint-disable-next-line no-unused-vars
import * as registerComponents from './modules/registerComponents.js';

import '@garmin/components-product/dist/style.css';

const app = createApp(App);

app.use(store);
app.use(router);

// Initialize Sentry
sentry(app);
// Initialize global PPGN containers
app.use(registerPPGNContainers);

app.mount('#app');
