import { createStore } from 'vuex';

// modules
import global from './global.js';
import content from './content.js';
import customerGroups from './customerGroups.js';
import gallery from './gallery.js';
import productInfo from './productInfo.js';
import productOptions from './productOptions.js';
import productTabs from './productTabs.js';
import productSidebar from './productSidebar.js';
import priceInfo from './priceInfo.js';
import mapsData from './mapsData.js';
import ctas from './ctas.js';
import interstitial from './interstitial.js';
import contentfulFeatureFlags from './contentfulFeatureFlags.js';
import kickers from './kickers.js';

export const storeSettings = {
  modules: {
    global,
    content,
    customerGroups,
    gallery,
    productInfo,
    productOptions,
    productTabs,
    mapsData,
    productSidebar,
    priceInfo,
    ctas,
    interstitial,
    contentfulFeatureFlags,
    kickers,
  },
  state: {
    bootstrap: null,
    customerGroup: 'none',
  },
  getters: {
    getBootstrap(state) {
      return state.bootstrap;
    },
    getProductBySku: (state, getters) => (sku) => {
      const bootstrap = getters.getBootstrap;

      return bootstrap.skus[sku];
    },
  },
  mutations: {
    setBootstrap(state, payload) {
      state.bootstrap = payload;
    },
  },
  actions: {
    async setBootstrap({ commit, dispatch }) {
      const bsData = { ...window.GarminAppBootstrap };
      commit('setBootstrap', bsData);
      await dispatch('setInitState');
    },
    async setInitState({ dispatch, getters }) {
      const bootstrap = getters.getBootstrap;
      const {
        translations,
        regions,
        sku,
        skus,
        filterDropdownList,
        devicesTabTitle,
        productEmsList,
        productCustomizations,
        affirmWidgetSrc,
        affirmPublicApiKey,
      } = bootstrap;
      const product = skus[sku];

      const productOptionsList = {
        productEmsList,
        filterDropdownList,
      };
      const { tabs } = product;
      dispatch('fetchContentfulEnvSettings');
      dispatch('setGlobalState', bootstrap);
      dispatch('setContent', translations);
      dispatch('setContentfulRegions', regions);
      dispatch('setDevicesTabTitle', devicesTabTitle);
      dispatch('setProductOptionsList', productOptionsList);
      dispatch('setProductCustomizations', productCustomizations);
      dispatch('setAffirmWidgetSrc', affirmWidgetSrc);
      dispatch('setAffirmPublicApiKey', affirmPublicApiKey);
      await dispatch('setCustomerGroups');
      await dispatch('setProductData', product);
      dispatch('setProductTabs', tabs);
    },
    async updateProductData({ dispatch, getters }, sku) {
      const product = getters.getProductBySku(sku);
      if (!product) return;

      const { tabs } = product;
      const tabsSettings = {
        tabsPayload: tabs,
      };

      await dispatch('setProductData', product);
      dispatch('updateProductTabs', tabsSettings);
      dispatch('resetInterstitial');
    },
    async setProductData({ dispatch, getters }, product) {
      const optionsList = getters.getProductEmsList;

      const {
        images,
        actions,
        expressWarning,
        options,
        sidebar,
      } = product;
      const ctasState = {
        actions: { ...actions },
        expressWarning,
      };
      const optionsData = {
        optionsList,
        options,
      };
      dispatch('setGallery', images);
      dispatch('setProductInfo', product);
      dispatch('setProductOptions', optionsData);
      await dispatch('callPricingProxy');
      dispatch('callKickersApi');
      dispatch('callKlarnaApi');
      dispatch('setSidebar', sidebar);
      dispatch('setCtas', ctasState);
    },
  },
};

export default createStore(storeSettings);
