import OverviewContainer from '../containers/OverviewContainer.vue';
import CompatibleDevices from '../containers/CompatibleDevices.vue';
import AccessoriesContainer from '../containers/AccessoriesContainer.vue';
import MapsContainer from '../containers/MapsContainer.vue';

const customElementPrefixes = ['g-', 'klarna-'];

/**
 *
 * @param {object} app - Vue app instance
 */
export default function registerPPGNContainers(app) {
  app
    .component('OverviewContainer', OverviewContainer)
    .component('CompatibleDevices', CompatibleDevices)
    .component('Accessories', AccessoriesContainer)
    .component('Maps', MapsContainer);

  app.config.compilerOptions.isCustomElement = (tag) => customElementPrefixes.some((prefix) => tag.includes(prefix));
  app.config.compilerOptions.onError = (error) => {
    // By default Vue 3 will throw an error when something is going wrong in the template of any component
    // We need to implement this custom logic here because the Overview/Specs content on the product page
    // contains HTML errors most of the times. Without this the page would not render correctly (NETFE-12404)

    // These errors codes are taken from here: @vue/compiler-core/dist/compiler-core.esm-bundler.js (line 17)
    /* eslint-disable no-multi-spaces */
    const allowedErrorCodes = [
      4,  // Illegal '/' in tags.
      12, // Illegal tag name. Use '&lt;' to print '<'.
      22, // Illegal '/' in tags.
      23, // Invalid end tag.
      24, // Element is missing end tag
      25, // Interpolation end sign was not found.
      26, // End bracket for dynamic directive argument was not found.
      27, // Legal directive name was expected.
      17, // Attribute name cannot contain U+0022 ("), U+0027 ('), and U+003C (<).
      2,  // Duplicate attribute.
    ];
    /* eslint-enable no-multi-spaces */

    if (!allowedErrorCodes.includes(error?.code)) {
      console.error(error);
    }
  };
}
