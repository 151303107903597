export default {
  state: {
    partNumber: '',
    productInfoId: '',
    productLegalText: '',
    productMarketingSummary: '',
    productName: '',
    globalProductName: '',
    productVariation: '',
    shippingDisclaimer: '',
    showPartNumber: false,
    showPrice: false,
    showProductVariationName: false,
    showCompatibleDevicesTab: false,
    productHeaderDetails: '',
    productAvailability: '',
    sellable: false,
    productEmsList: [],
    filterDropdownList: [],
    pvProductEcommerceOpenText: '',
    productKickerData: {},
    productCustomizations: [],
    pvName: '',
    affirmWidgetSrc: '',
    affirmPublicApiKey: '',
    isAffirmScriptLoaded: false,
  },
  getters: {
    getPartNumber(state) {
      return state.partNumber;
    },
    getPvProductEcommerceOpenText(state) {
      return state.pvProductEcommerceOpenText;
    },
    getProductInfoId(state) {
      return state.productId;
    },
    getProductLegalText(state) {
      return state.productLegalText;
    },
    getShowProductLegalText(state, getters) {
      return !!getters.getProductLegalText.length;
    },
    getProductMarketingSummary(state) {
      return state.productMarketingSummary;
    },
    getShowProductMarketingSummary(state, getters) {
      return !!getters.getProductMarketingSummary.length;
    },
    getProductName(state) {
      return state.productName;
    },
    getGlobalProductName(state) {
      return state.globalProductName;
    },
    getProductVariation(state) {
      return state.productVariation;
    },
    getShippingDisclaimer(state) {
      return state.shippingDisclaimer;
    },
    getShowShippingDisclaimer(state, getters) {
      return !!getters.getShippingDisclaimer.length;
    },
    getProductHeaderDetails(state) {
      return state.productHeaderDetails;
    },
    getShowProductHeaderDetails(state, getters) {
      return !!getters.getProductHeaderDetails.length;
    },
    getShowProductName(state, getters) {
      return !!getters.getProductName;
    },
    getShowPartNumber(state) {
      return state.showPartNumber;
    },
    getShowPrice(state) {
      return state.showPrice;
    },
    getShowProductVariationName(state) {
      return state.showProductVariationName;
    },
    getShowCompatibleDevicesTab(state) {
      return state.showCompatibleDevicesTab;
    },
    getProductAvailability(state) {
      return state.productAvailability;
    },
    getShowProductAvailability(state, getters) {
      return !!getters.getProductAvailability.length;
    },
    getSellable(state) {
      return state.sellable;
    },
    getProductEmsList(state) {
      return state.productEmsList;
    },
    getFilterDropdownList(state) {
      return state.filterDropdownList;
    },
    getFilterDropdownSelected(state) {
      // Return the item that is selected or the first one in the list if nothing is selected
      return state.filterDropdownList.find((option) => option.selected)
             || state.filterDropdownList[0]
             || {};
    },
    getProductCustomizations(state) {
      return state.productCustomizations;
    },

    getProductCustomizationsByPid: (state, getters) => (pid) => getters.getProductCustomizations?.find((customization) => customization?.productId === pid),
    getPvName: (state) => state.pvName,
    getAffirmWidgetSrc: (state) => state.affirmWidgetSrc,
    getAffirmPublicApiKey: (state) => state.affirmPublicApiKey,
    getIsAffirmScriptLoaded: (state) => state.isAffirmScriptLoaded,
    /**
     * Determines whether the Affirm widget should be displayed.
     * The widget is shown only if the "Add to Cart" button is visible
     * and the user's locale is set to 'en-US'.
     * @param {object} state - The Vuex state object (unused in this getter).
     * @param {object} getters - The Vuex getters object.
     * @param {boolean} getters.getShowAddToCartBtn - Whether the "Add to Cart" button is visible.
     * @param {string} getters.getLocale - The current locale of the user.
     * @returns {boolean} `true` if the Affirm widget should be displayed, otherwise `false`.
     */
    getShowAffirmWidget: (state, getters) => getters.getShowAddToCartBtn && getters.getLocale === 'en-US',
    getShowKlarnaWidget: (state, getters) => {
      /**
       * Display the Klarna widget if:
       * - There is valid widget metadata.
       * - and the locale is not en-US.
       */
      const hasWidgetMetadata = Object.keys(getters.getWidgetMetadata)?.length > 0;
      return hasWidgetMetadata && (getters.getLocale !== 'en-US');
    },
  },
  mutations: {
    setPartNumber(state, payload) {
      state.partNumber = payload;
    },
    setProductInfoId(state, payload) {
      state.productId = payload;
    },
    setProductMarketingSummary(state, payload) {
      state.productMarketingSummary = payload || '';
    },
    setProductName(state, payload) {
      state.productName = payload;
    },
    setGlobalProductName(state, payload) {
      state.globalProductName = payload;
    },
    setProductVariation(state, payload) {
      state.productVariation = payload;
    },
    setShippingDisclaimer(state, payload) {
      state.shippingDisclaimer = payload || '';
    },
    setProductHeaderDetails(state, payload) {
      state.productHeaderDetails = payload || '';
    },
    setProductLegalText(state, payload) {
      state.productLegalText = payload || '';
    },
    setShowPartNumber(state, payload) {
      state.showPartNumber = payload;
    },
    setShowPrice(state, payload) {
      state.showPrice = payload;
    },
    setShowProductVariationName(state, payload) {
      state.showProductVariationName = payload;
    },
    setShowCompatibleDevicesTab(state, payload) {
      state.showCompatibleDevicesTab = !!payload;
    },
    setProductAvailability(state, payload) {
      state.productAvailability = payload || '';
    },
    setSellable(state, payload) {
      state.sellable = payload;
    },
    setProductEmsList(state, payload) {
      state.productEmsList = payload;
    },
    setFilterDropdownList(state, payload) {
      state.filterDropdownList = payload;
    },
    setFilterDropdownSelected(state, payload) {
      state.filterDropdownList = state.filterDropdownList.map((option) => {
        option.selected = payload === option.value;
        return option;
      });
    },
    setPvProductEcommerceOpenText(state, payload) {
      state.pvProductEcommerceOpenText = payload;
    },
    setProductCustomizations(state, payload) {
      state.productCustomizations = payload;
    },
    setPvName(state, payload) {
      state.pvName = payload;
    },
    setAffirmWidgetSrc(state, payload) {
      state.affirmWidgetSrc = payload;
    },
    setAffirmPublicApiKey(state, payload) {
      state.affirmPublicApiKey = payload;
    },
    setIsAffirmScriptLoaded(state, payload) {
      state.isAffirmScriptLoaded = payload;
    },
  },
  actions: {
    setProductInfo({ commit, dispatch }, payload) {
      const {
        partNumber,
        productId,
        productLegalText,
        promoBanner,
        productMarketingSummary,
        productName,
        globalProductName,
        productVariation,
        shippingDisclaimer,
        showPartNumber,
        showPrice,
        showProductVariationName,
        showCompatibleDevicesTab,
        productHeaderDetails,
        productAvailability,
        sellable,
        seo,
        pvProductEcommerceOpenText,
        pvName,
      } = payload;

      commit('setPartNumber', partNumber);
      commit('setProductId', productId);
      commit('setProductLegalText', productLegalText);
      commit('setProductMarketingSummary', productMarketingSummary);
      commit('setProductName', productName);
      commit('setGlobalProductName', globalProductName);
      commit('setProductVariation', productVariation);
      commit('setShippingDisclaimer', shippingDisclaimer);
      commit('setShowPartNumber', showPartNumber);
      commit('setShowPrice', showPrice);
      commit('setShowProductVariationName', showProductVariationName);
      commit('setShowCompatibleDevicesTab', showCompatibleDevicesTab);
      commit('setProductHeaderDetails', productHeaderDetails);
      commit('setProductAvailability', productAvailability);
      commit('setSellable', sellable);
      dispatch('setPromoBanner', promoBanner);
      dispatch('setSeoTitle', seo.title);
      dispatch('setPvProductEcommerceOpenText', pvProductEcommerceOpenText);
      commit('setPvName', pvName);
    },
    setProductOptionsList({ commit }, payload) {
      const { productEmsList, filterDropdownList } = payload;

      /*
        remove empty EMS items and EMS items that have less than 2 options
        No point in showing EMS items in the UI that only have one option to choose from
      */
      const productEmsListCleaned = productEmsList.filter((ems) => {
        if (!Object.entries(ems).length) return false;
        if (ems.options && ems.options.length === 1) return false;
        return true;
      });

      commit('setProductEmsList', productEmsListCleaned);
      commit('setFilterDropdownList', filterDropdownList);
    },
    setProductCustomizations({ commit }, payload) {
      commit('setProductCustomizations', payload);
    },
    setFilterDropdownSelected({ commit }, payload) {
      commit('setFilterDropdownSelected', payload);
    },
    setShowCompatibleDevicesTab({ commit }, payload) {
      commit('setShowCompatibleDevicesTab', payload);
    },
    setSeoTitle(context, title) {
      // use native dom parser to parse special characters in title string
      const parser = new DOMParser();
      const dom = parser.parseFromString(title, 'text/html');

      // Prevent setting the title to null if we don;t have a seo title
      if (title && dom.body.textContent) {
        document.title = dom.body.textContent;
      }
    },
    setPvProductEcommerceOpenText({ commit }, payload) {
      commit('setPvProductEcommerceOpenText', payload);
    },
    async callKlarnaApi({ dispatch, getters }) {
      const {
        getBuyGarminEndpoint,
        getLocale,
        getPriceData,
        getSellable,
        getShowPrice,
      } = getters;
      const currencyCode = window?.utag_data?.currency_code;

      // If the product is not sellable we don't want Klarna to display
      if (!getSellable || !getShowPrice) {
        dispatch('setWidgetMetadata', {});
        return;
      }

      let price = '';
      if (getPriceData?.salePrice?.price) {
        price = getPriceData?.salePrice?.price;
      } else {
        price = getPriceData?.listPrice?.price;
      }

      const url = `${getBuyGarminEndpoint}/marketing-services/widgets/klarna?price=${price}&currency=${currencyCode}&locale=${getLocale}`;

      try {
        const response = await fetch(url, {
          method: 'get',
        });

        const klarnaData = await response.json();
        if (klarnaData) {
          dispatch('setWidgetMetadata', klarnaData);
        } else {
          dispatch('setWidgetMetadata', {});
        }
      } catch {
        dispatch('setWidgetMetadata', {});

        console.error('Error calling klarna marketing-services');
      }
    },
    setAffirmWidgetSrc({ commit }, payload) {
      commit('setAffirmWidgetSrc', payload);
    },
    setAffirmPublicApiKey({ commit }, payload) {
      commit('setAffirmPublicApiKey', payload);
    },
    setIsAffirmScriptLoaded({ commit }, payload) {
      commit('setIsAffirmScriptLoaded', payload);
    },
  },
};
