/**
 * @param {string} source  - A string that may correspond to a media content source, that was retrieved from a Contentful ContentBlock model.
 * @returns {string | boolean}   - returns the adequate source for the content, removing the name of the file at the beginning
 *                               - returns false if the string doesn't have a media content source format
 * @example
 * convertImageSource(![image_name](//this.is-a.valid/image/source)) => //this.is-a.valid/image/source
 */
export const convertImageSource = (source) => {
  const regex = /^!\[(.*?)\]\((.*?)\)$/;
  return source.match(regex) ? source.match(regex)[2] : false;
};
